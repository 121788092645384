<template>
  <div class="del-wrap">
    <el-dialog title="修改余额"
               :visible="balanceDialogVisible"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false"
               width="500px"
               center>
      <div class="divide-wrap">
        <el-form ref="form"
                 label-position="left"
                 :model="form"
                 label-width="80px"
                 prop="trans_type">
          <el-row class="row-wrap">
            <el-form-item label="金额:"
                          class="mg50"
                          style="width:140px"
                          prop="trans_type">
              <el-select v-model="form.trans_type"
                         class="rightBorder"
                         style="width:100%"
                         placeholder="请选择">
                <el-option label="增加"
                           value="1"></el-option>
                <el-option label="减去"
                           value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="amount"
                          style="width:320px"
                          class="mg0">
              <el-input-number :min="0"
                               style="width:100%"
                               :max="10000000000"
                               :precision="0"
                               controls-position="right"
                               placeholder="请输入内容"
                               v-model="form.amount"
                               class="input-with-select"></el-input-number>
            </el-form-item>
          </el-row>
          <el-form-item label="类型:"
                        class="mg50"
                        prop="wallet_type">
            <el-select v-model="form.wallet_type"
                       style="width:100%"
                       placeholder="请选择">
              <el-option label="本月"
                         value="coin"></el-option>
              <el-option label="上月"
                         value="diamond"></el-option>
              <el-option label="历史收益"
                         value="history"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="验证码:"
                        class="mg50"
                        prop="verify_code">
            <el-input v-model="form.verify_code"
                      placeholder="请输入验证码">
              <el-button type="text"
                         slot="suffix"
                         style="margin-right:16px;"
                         @click="_getCode">{{secondrender}}</el-button>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="cancleDialog">取 消</el-button>
        <el-button type="primary"
                   @click="submitDialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    balanceDialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      form: {
        trans_type: "1",
        amount: 0,
        wallet_type: "coin",
        verify_code: "",
      },
      second: 0,
    };
  },
  methods: {
    cancleDialog () {
      this.$emit("cancleDialog", "balanceDialogVisible");
    },
    submitDialog () {
      this.$emit("submitBalanceDialog", this.form);
    },
    async _getCode () {
      if (this.second > 0) {
        return;
      }
      const { status_code, message } = await this.$api.phoneTransferVerify();
      if (status_code == 200) {
        this.$message.success({ message: '发送成功' });
        this.second = 60;
        let js = setInterval(() => {
          this.second--;
          if (this.second == 0) {
            clearInterval(js);
          }
        }, 1000);
      } else if (status_code == 422) {
        Object.keys(message).forEach(key => {
          this.$message.error({ message: message[key] });
        })
      } else {
        this.$message.error({ message });
      }
    },
  },
  computed: {
    secondrender () {
      return this.second == 0 ? "获取验证码" : this.second + "S";
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  text-align: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.del-wrap /deep/ button.el-button.el-button--default {
  width: 85px;
  height: 32px;
}
.row-wrap {
  display: flex;
  justify-content: flex-start;
}
.mg0 /deep/ .el-form-item__content {
  margin-left: 0 !important;
}
.divide-wrap /deep/ label.el-form-item__label {
  width: 60px !important;
}
.mg50 /deep/ .el-form-item__content {
  margin-left: 60px !important;
}
.rightBorder /deep/ .el-input--suffix .el-input__inner {
  border-right: 1px solid #fff;
  border-radius: 4px 0 0 4px;
}
.del-wrap /deep/ .el-input-number.is-controls-right .el-input__inner {
  border-radius: 0 4px 4px 0;
}
</style>